<template>
  <div>
    <div class="bgcenters">
            <van-nav-bar
            left-arrow
            fixed
            :border="false"
            safe-area-inset-top
            placeholder
            :title="orderType=='PRE_MORTGAGE'?'存料预付':'存料结算'"
            @click-left="leftClick"
             />
    </div>
    <div>

      <div class="centerboxs">
        <!-- 定价详情 -->
        <div class="topbg"></div>
        <!-- 订单详情 -->
        <div class="orderbox">
          <center>
            <!-- 用户没确认 -->
            <h1 class="f32" v-if="StockData.status == '2'">
              请确认下方结算信息
            </h1>
            <!-- 用户已经确认 -->
            <h1 class="f32"  v-if="StockData.status == '1'">
              打款已完成，请查收
            </h1>
            <h1 class="f32"  v-if="StockData.status == '0'">您的款项正在飞速打款中....</h1>
            <div class="money">
              <b class="f32">￥{{orderType=='PRE_MORTGAGE'?StockData.moneyTotal:StockData.totalMoney}}</b>
            </div>
            <el-button class="btnsee f32" @click="dianzidanju">查看电子单据</el-button
            >
          </center>
        </div>
        <div class="topcenter">
          <!-- 物料明细 是待确认数据不显示 -->
          <div v-if="StockData.status == '2'">
         <div class="toptitle">物料明细</div>
          <div >
            <div class="flex-between" v-for="(item,index) in StockData.list_wuLiao" :key="index">
              <div>{{item.name}}</div>
              <div>{{item.weight}}克</div>
            </div>
            <div class="flex-between">
              <div>物料总重</div>
              <div>{{StockData.weight}}克</div>
            </div>
            <div class="flex-between">
              <div>物料折足总重</div>
              <div>{{StockData.weightZeZu}}克</div>
            </div>
          </div>
          </div>
          <!-- 结算明细 -->
            <div>
                 <div class="toptitle mart">结算明细</div>
                 <div class="sectionbox">
                     <div class="flex-betweens">
                       <div>存料克重:</div>
                       <div>{{ (StockData.status == '2'  || StockData.status == '0') && orderType=='PRE_MORTGAGE'?StockData.weightZeZu:StockData.weight}}克</div>
                     </div>
                     <div class="flex-betweens">
                       <div>{{orderType=='PRE_MORTGAGE'?'预估金价:':'结算金价:'}}</div>
                       <div>{{ orderType=='PRE_MORTGAGE'?StockData.priceYuGu:StockData.pricePinCang}}克/元</div>
                     </div>
                     <div class="flex-betweens">
                    <div>{{orderType=='PRE_MORTGAGE'?'预估价值:':'结算价值:'}}</div>
                      <div class="blackFont">{{orderType=='PRE_MORTGAGE'?StockData.moneyYuGu:StockData.moneyPinCang}}克</div>
                   </div>
                  </div>
            </div>
          <!-- 存料预付  是待确认数据不显示-->
            <div  v-if="orderType=='PRE_MORTGAGE'">
                 <div class="toptitle mart">存料预付</div>
                 <div class="sectionbox">
                   <div class="flex-betweens">
                     <div>预付比例:</div>
                     <div class="blackFont">{{StockData.percentageYuFu}}%</div>
                   </div>
                   <div class="flex-betweens">
                     <div>预付金额:</div>
                     <div class="orangeColor">{{StockData.moneyYuFu}}元</div>
                   </div>
                 </div>
            </div>
          <!-- 存料结算    是待确认数据不显示-->
                <div v-if="orderType!='PRE_MORTGAGE'">
                     <div class="toptitle mart" >存料结算</div>
                     <div>
                       <div class="flex-between">
                         <div>首饰折价：</div>
                         <div class="blackFont">{{StockData.moneyTiChun}}元</div>
                       </div>
                       <div class="flex-between">
                         <div>快递费：</div>
                         <div class="blackFont">{{StockData.moneyYunFei}}元</div>
                       </div>
                       <div class="flex-between">
                         <div>保险费：</div>
                         <div class="blackFont">{{StockData.moneyBaoXiang}}</div>
                       </div>
                       <div class="flex-between">
                         <div>累计延时费:</div>
                         <div class="blackFont">{{StockData.moneyStore}}</div>
                       </div>
                       <div class="flex-between">
                         <div>累计补押金:</div>
                         <div class="blackFont">{{StockData.moneyBuCang}}元</div>
                       </div>
                       <div class="flex-between">
                         <div>存料预付:</div>
                         <div class="blackFont">{{StockData.moneyYuFu}}元</div>
                       </div>
                       <div class="flex-between">
                         <div>剩余应付:</div>
                         <div class="orangeColor">{{StockData.moneyShenYu}}元</div>
                       </div>
                     </div>
                </div>

          <!-- 订单信息 -->
          <div class="toptitle mart  info">订单信息</div>
          <div>
            <div class="flex-between_ flex">
              <div>顺丰单号：</div>
              <div>{{StockData.orderNoExpress?StockData.orderNoExpress:'--'}}</div>
            </div>
            <div class="flex-between_ flex">
              <div>结算单号：</div>
              <div>{{StockData.orderNo}}</div>
            </div>
            <div class="flex-between_ flex">
              <div>开单时间：</div>
              <div>{{StockData.status == '2'?StockData.date:StockData.dateYaPan}}</div>
            </div>
            <div class="flex-between_ flex" v-if="StockData.status != '2'">
              <div>结算时间：</div>
              <div>{{StockData.datePinCang}}</div>
            </div>
            <div class="flex-between_ flex">
              <div>用户姓名：</div>
              <div>{{StockData.name}}</div>
            </div>
            <div class="flex-between_ flex">
              <div>联系电话：</div>
              <div>{{StockData.phone}}</div>
            </div>
            <div class="flex-between_ flex">
              <div>收款账号：</div>
              <div>{{StockData.bankName + StockData.bankNum}}</div>
            </div>
            <div class="flex-between_ flex">
              <div>备注信息：</div>
              <div>{{StockData.info?StockData.info:'--'}}</div>
            </div>
          </div>
        </div>
        <!-- 用户没确认  待确认数据显示-->
        <center class="rel" v-if="StockData.status =='2'">
          <el-button class="btn_red onlybox" @click="orderObjection"
            >对结算单有异议？</el-button
          >
          <el-button class="btn_red onlyboxsecond_yellow"   @click="confirm"
            >确认无误</el-button
          >
        </center>

        <center  class="rel" v-if="StockData.status =='0'">
          <el-button class="btn_red onlybox" @click="orderObjection"
            >对结算单有异议？</el-button
          >
          <el-button
            class="btn_red overconfirm onlyboxsecond"
            >已确认</el-button
          >
        </center>

        <!-- 用户已确认 -->
<!--        <center  class="rel" v-if="StockData.status =='1'">
          <el-button class="btn_red" style="width:90%"
            >再来一单</el-button
          >
        </center>-->
      </div>
      <!-- 弹窗 -->
      <van-popup v-model="popupbox">
        <div class="titlebox_popup">提示</div>
        <div class="detailsbox">
          如结算单有误需拨打{{GLOBAL}}客服人工核对修改，是否拨打{{GLOBAL}}客服电话：4008-606-806
        </div>
        <div class="flex btn-flex rel">
          <div class="canclebox" @click="popupbox = !popupbox">取消</div>
          <div class="RedF" @click="playPhone">确认</div>
        </div>
      </van-popup>
      <!-- 确认无误 -->
      <van-popup v-model="iscomfirm">
        <div class="titlebox_popup">结算确认</div>
        <div class="detailsbox">
          尊敬的用户，您正在进行结算确认，请仔细核对您的来料重量与结算价格。确认无误后，{{GLOBAL}}将在一个工作日内完成打款。
        </div>
        <div class="flex btn-flex rel">
          <div class="canclebox" @click="iscomfirm = !iscomfirm">取消</div>
          <div class="RedF" @click="querenbox">确认</div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import *as api from'@/utils/api'
export default {
        data() {
            return {
            iscomfirm:false,
            popupbox:false,
            StockData:{},
              orderType: 'PRE_MORTGAGE',
            }
        },
        methods:{
          // 拨打客服电话按钮
          playPhone(){
            window.location.href = "tel:// 4008-606-806";
          },
          // 确认无误按钮事件
          confirm(){
            this.iscomfirm = true
          },
          // 结算单异议按钮事件
          orderObjection(){
            this.popupbox = true

          },
          // 导航栏左侧事件
          leftClick(){
            this.$router.push({
              name:'keepRecord',
              query: {active:"1", isWaitHandle: this.$route.query.isWaitHandle}
            })
          },
          //确认存料单
          querenbox() {
            api.sureJieSuanOrder({ orderId: this.$route.query.id })
              .then(res => {
                this.$router.push({ name: "keepRecord",query: {active:"1", isWaitHandle: this.$route.query.isWaitHandle} });
              });
          },
          // 查看电子单据
          dianzidanju() {
            this.$router.push({
              name: "stockBalanceTable",
              query: { oid: this.$route.query.id, orderType:this.$route.query.orderType, isWaitHandle: this.$route.query.isWaitHandle}
            });
          },
          forceLandscapeScreenHandle1() {
            const body = document.getElementsByTagName('body')[0];
            const html = document.getElementsByTagName('html')[0];
            const width = html.clientWidth;
            const height = html.clientHeight;
            const max = width > height ? width : height;
            const min = width > height ? height : width;
            body.style.width = min + "px";
            body.style.height = max + "px";
          },
        },
async   created(){
          this.forceLandscapeScreenHandle1();
            const orderId = this.$route.query.id
            this.orderType = this.$route.query.orderType
            if ( this.orderType=='PRE_MORTGAGE') {

                // 跳转过来的数据是待确认WAIT_CONFIRM  用getJieSuanStockData接口
                   let res = await api.getJieSuanStockData({orderId})
                       this.StockData = res.data
                       console.log(res);

            }else{
                // 跳转过来的数据不是待确认WAIT_CONFIRM  用getStockData接口
                   let res = await api.getStockData({orderId})
                       this.StockData = res.data
                       console.log(this.StockData);

            }



        }
}
</script>

<style lang="scss" scoped="true">
::v-deep{
     .van-nav-bar{
       background-color: #ff2d2e;
        height: 60px;
        .van-nav-bar__content{
       background-color: #ff2d2e;
        line-height: 70px;
        height: 70px;
        .van-nav-bar__title{
            font-size: 34px;
            color: #fff;

        }
        .van-icon{
            font-size: 36px;
            color: #fff;
        }
        }
     }
}
.onlyboxsecond {
  border: 1px solid #e0e0e0 !important;
}
.onlyboxsecond_yellow {
  border: 1px solid #ffa102 !important;
}

.bgcenters {
  position: relative;
  z-index: 3;
}
.RedF {
  color: #FF2D2E;
  width: 240px;
  text-align: center;
}
.canclebox {
  width: 240px;
  text-align: center;
  &:after {
    content: "";
    background: #cccccc;
    width: 1px;
    height: 90px;
    top: 0;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
  }
}
.btn-flex {
  margin: 0 30px;
  padding: 20px 0;
  font-size: 32px;
  justify-content: space-around;
  border-top: 1px solid #cccccc;
}
::v-deep .van-popup--center {
  width: 600px;
  border-radius: 12px;
}
.detailsbox {
  color: #292929;
  font-size: 30px;
  margin: 0 40px 30px;
}
.titlebox_popup {
  font-size: 36px;
  text-align: center;
  color: #292929;
  margin: 40px 0;
}
.sectionbox {
  margin-bottom: 30px;
  .flex-betweens {
    margin-bottom: 7px;
    font-size: 28px;
    color: #999999;
  }
}
.flex-between_ {
  line-height: 38px;
  margin-bottom: 7px;
  font-size: 28px;
  color: #999999;
  div:nth-child(1) {
    margin-right: 10px;
  }
}
.flex-betweens {
  display: flex;
  justify-content: space-between;
}
.countyellow {
  color: #ffb606 !important;
}
.blackFont {
  color: #010000 !important;
}
.mart {
  margin-top: 60px;
}
.toptitle {
  margin-bottom: 25px;
  font-size: 36px;
  color: #010000;
  font-weight: bold;
}
.topcenter {
  margin-top: 250px;
  padding: 0 60px 150px;
}
.money {
  padding: 40px 0;
  font-size: 52px;
}
.f32 {
  font-size: 32px;
}

.btnsee {
  border: 1px solid #FF2D2E;
  color: #fff;
  width: 480px;
  height: 64px;
  background: #FF2D2E;
  border-radius: 32px;
}
.btn_red {
  width: 320px;
  position: relative;
  bottom: 30px;
  border-radius: 8px;
  height: 80px;
  border: 0;
  background: #FF2D2E;
  font-size: 36px;
  color: #fff;
}
.onlybox {
  font-size: 28px;
  border: 1px solid #cccccc;
  color: #999999;
  background: #f5f5f5;
}
.overconfirm {
  position: relative;
  top: -27px;
  border: 1px solid #e0e0e0 !important;
  background: #e0e0e0;
}

.topboxcenter {
  position: absolute;
  top: 120px;
  left: 60px;
  i {
    font-size: 60px;
    margin-top: 10px;
  }
  .topbox {
    div {
      font-weight: bold;
      margin-left: 20px;
      font-size: 40px;
    }
    .orderdetails {
      font-weight: normal;
      font-size: 28px;
    }
  }
}
.bgcenter {
  background: #fff;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.centerboxs {
  position: relative;
  z-index: 1;
}
.ordermunber {
  font-weight: bold;
  font-size: 28px;
  color: #010000;
  border-bottom: 1px dotted #e6e6e6;
  padding-bottom: 25px;
  margin-bottom: 25px;
}
.flex-between div:nth-child(1) {
  color: #999999;
  font-size: 28px;
  margin-bottom: 15px;
}
.flex-between div:nth-child(2) {
  font-size: 28px;
  color: #999999;
}
.orderbox {
  width: 690px;
  border-radius: 12px;
  padding: 50px;
  background: #fff;
  position: absolute;
  left: 50%;
  margin-left: -345px;
  top: 20px;
  box-shadow: 0 3px 9px 3px #e2e2e2;
}
.topbg {
  background: #ff2d2e;
  width: 750px;
  height: 153px;
}
.redfont {
  color: #fa6054;
}
.footerbox {
  position: fixed;
  bottom: 30px;
  margin: 0 30px;
  .addone {
    border-radius: 8px;
    font-size: 36px;
    border: 1px solid #ffb606;
    color: #ffb606;
    height: 88px;
    width: 240px;
    margin-right: 30px;
  }
  .gosend {
    border-radius: 8px;
    font-size: 36px;
    width: 420px;
    height: 88px;
    border: 1px solid #ffb606;
    background: #ffb606;
    color: #000000;
  }
}
.imgminbox {
  margin-left: 25px;
  // margin: 0 auto;
  width: 64px;
  height: 64px;
  margin-bottom: 20px;
}
.bg_box {
  // background: red;
  background: #f7f7f7;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.personbox {
  z-index: 2;
  position: relative;
}
.righticon {
  width: 20px;
  right: 0;
  top: 30%;
  transform: translate(-50%);
  position: absolute;
}
.abs {
  position: absolute;
  top: 150px;
  left: 30px;
  color: #fff;
  .imgbox {
    width: 100px;
  }
}
.list {
  border-radius: 8px;
  margin-top: 20px;
  background: #fff;
}
.msgbox {
  border-radius: 8px;
  background: #fff;
  position: relative;
  top: -50px;
  padding: 30px 40px;
  width: 690px;
  margin: 0 auto;
  color: #999999;
  font-size: 24px;
  b {
    font-size: 40px;
    font-weight: normal;
  }
  .firstdiv {
    width: 320px;
    border-right: 1px dotted #e6e6e6;
  }
  .seconddiv {
    width: 300px;
    margin-left: 40px;
  }
}
.namebox {
  margin-left: 30px;
  font-size: 36px;
  .phone {
    font-size: 26px !important;
  }
}
.centerbox {
  padding: 0 30px;
  .mintitle {
    color: #343434;
    font-size: 30px;
    padding: 25px 30px;
    border-bottom: 1px solid #e6e6e6;
  }
  .flexs {
    padding: 0 25px;
    display: flex;
    div.flexbox {
      flex: 1;
      font-size: 26px;
      color: #707070;
    }
  }
  div.flexbox div:nth-child(2) {
    text-indent: 5px;
  }
  .flex {
    padding: 0 15px 0 35px;
    div.flexbox {
      flex: 1;
      font-size: 26px;
      color: #707070;
      margin: 35px 0px 60px 0;
    }
  }
}
#loadingbox {
  margin-top: 150px;
}
.info{
  padding: 40px 0 20px;
  border-top: 1px solid #ccc;
  //border-bottom: 1px solid #ccc;
}
.orangeColor{
  color: #ff2d2e !important;
}
</style>
